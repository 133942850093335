.ant-tooltip {
  z-index: 100000;
}

/* Track style */
.ant-slider-track {
    background-color: white !important; /* Change this to your preferred color */
  }
  
  /* Handle style */
  .ant-slider-handle {
    border: solid 2px #4caf50 !important; /* Change this to your preferred color */
  }
  
  /* Dot style */
  .ant-slider-dot {
    border: solid 2px #4caf50 !important; /* Change this to your preferred color */
  }
  /* Tooltip style */
.ant-tooltip-inner {
  background-color: white !important; /* Change this to your preferred color */
  color: #141626 !important; /* Change this to your preferred color */
  font-size: 14px !important; /* Change this to your preferred size */
  font-weight: bold !important; /* Make the text bold */
}
/* Non-selected rail style */
.ant-slider-rail {
  background-color: #868686 !important; /* Change this to your preferred color */
}