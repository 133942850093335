
.marquee {
    position: relative;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
}

.marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s linear infinite;
}

@keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
}
