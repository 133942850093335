/* For Webkit-based browsers (Chrome, Safari and Opera) */
.custom-scrollbar::-webkit-scrollbar {
    width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #2A2A2B; 
    border-radius: 30px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* For IE, Edge and Firefox */
.custom-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
    scrollbar-color: #2A2A2B transparent;  /* Firefox */
}
