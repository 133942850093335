.nice-dates-week-header_day:nth-child(7) {
  color: red;
}

.nice-dates-popover {
 
  z-index: 20000;
}

/* Assuming mobile view starts from 480px or lower */
@media screen and (max-width: 480px) {
  .nice-dates-popover {
      /* Reset left position and remove transform property to start from left side */
      left: 0;
      transform: none;
      width: 90%;
      max-width: 600px;
      margin: 10px 20px;
    
      
  }
}
