/* For Webkit-based browsers (Chrome, Safari and Opera) */
@media (min-width: 641px) {
    .custom-scrollbar2::-webkit-scrollbar {
        height: 8px;  /* decrease the width from 10px to 5px */
    }

    .custom-scrollbar2::-webkit-scrollbar-track {
        background: transparent; 
    }

    .custom-scrollbar2::-webkit-scrollbar-thumb {
        background: #2A2A2B; 
        border-radius: 30px;
    }

    .custom-scrollbar2::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}

@media (max-width: 640px) {
    .custom-scrollbar2::-webkit-scrollbar {
        display: none;
    }
}

/* For IE, Edge and Firefox */
.custom-scrollbar2 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
    scrollbar-color: #2A2A2B transparent;  /* Firefox */
}

@media (max-width: 640px) {
    .custom-scrollbar2 {
        -ms-overflow-style: none;  /* IE and Edge */
        /* scrollbar-width: none;  Firefox */
        scrollbar-width: thin;  /* Firefox */
    scrollbar-color: #2A2A2B transparent;  /* Firefox */
    }
}
