/*======================
    404 page
=======================*/


.page_404 {
  padding: 0 0;
  background: #fff;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif);
  height: 650px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 90px;
}

.four_zero_four_bg h3 {
  font-size: 90px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #000000;
  margin: 20px 0;
  display: inline-block;
  border-radius: 10%;
}

.contant_box_404 {
  margin-top: -50px;
}