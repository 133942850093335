.modal-slide-enter {
    transform: translateX(100%);
    }
    
    .modal-slide-enter-active {
    transform: translateX(0%);
    transition: transform 200ms ease-in-out;
    }
    
    .modal-slide-exit {
    transform: translateX(0%);
    }
    
    .modal-slide-exit-active {
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out;
    }
    
    .modal-slide-enter-done,
    .modal-slide-exit-done {
    position: absolute;
    }