@keyframes shake {
    0%, 50% {
        transform: translateX(0);
      }
    
      5%, 15%, 25%, 35%, 45% {
        transform: translateX(-2px);
      }
    
      10%, 20%, 30%, 40% {
        transform: translateX(2px);
      }
  }
  
  .shake-shake {
    animation: shake 2s ease-in-out infinite;
  }