:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 300px;
  width: 300px;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  /* margin: 2rem; */
}

@media only screen and (max-width: 768px) {
  .card {
    height: 265px;
    width: 265px;
    /* margin: 1rem 0; */
  }
}
@media only screen and (max-width: 632px) {
  .card {
    height: 78vw;
    width: 78vw;
    /* margin: 1rem 0; */
  }
}

@media only screen and (max-width: 369.9px) {
  .card {
    height: 275px;
    width: 275px;
    /* margin: 1rem 0; */
  }
}
.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* height: 100%; */
  /* height: auto; */
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}
/* @media only screen and (max-width: 768px) {
  .card__overlay {
    bottom: auto;
    top: 0;
  }
} */
.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 1em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .card__header {
    padding: 0.85em;
    border-radius: 0;
    border-radius: calc(var(--curve) * 1px) 0 0 0
  }
}


.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 99%;
  right: 0;
  z-index: 1;
}


.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
  transform: translateY(0);
}

@media only screen and (max-width: 768px) {
  .card__arc {
    width: 80px;
  height: 80px;
  position: absolute;
  bottom: 99%;
  right: 0;
  z-index: 1;
  }
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  /* margin: 0 0 .3em; */
  color: #6a515e;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: #d7bdca;
}

@media only screen and (max-width: 768px) {
  .card__thumb {
    width: 40px;
    height: 40px;
  }
  
  .card__title {
    font-size: 0.8em;
  }
  
  .card__tagline {
    font-size: 0.7em;
  }
}

.card__status {
  font-size: 0.8em;
  color: #d7bdca;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #000000;
  border: 5px;
  /* font-family: "MockFlowFont";    */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .card__status {
    font-size: 0.7em;
  }
  
  .card__description {
    -webkit-line-clamp: 2;
  }
}